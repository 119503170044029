import React, { Suspense, lazy }  from "react"; 
import './App.css';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Loader from "./component/Loader";
import NotFound from "./component/NotFound";
import { Menu } from "./component/Menu";

const Home = lazy(() => import("./component/home")); 
const Edu = lazy(() => import("./component/education")); 
const Skill = lazy(() => import("./component/skill")); 
const Done = lazy(() => import("./component/other")); 

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={
          <>
            <Loader/>
          </>
        }> 
          <Router> 
            <div className="App container-fluid"> 
              <Menu/> 
              <Routes>
                <Route exact path="/" element={<Home />}/> 
                <Route path="/home" element={<Home />} />
                <Route path="/education" element={<Edu />} />
                <Route path="/skills" element={<Skill />} />
                <Route path="/done" element={<Done />} />
                <Route path="*" element={<NotFound />}/>
              </Routes> 
            </div>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
