import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import promiseMiddleware from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";

const logger = createLogger({});
const persisConfig = {
  key: "root",
  storage
};
const pReducer = persistReducer(persisConfig, reducers);
const store = createStore(
  pReducer,
  compose(
    applyMiddleware(logger, promiseMiddleware) 
  )
);
const persistor = persistStore(store);
export { store, persistor };