const initialState = {
    todolist: { 
        data:[]
    }
}
function TodoList (state = initialState, action) {
    switch (action.type) {
        case 'REMOVE':
            const idx = action.payload.data;
            state.todolist.data.splice(idx,1);
            return {
                ...state 
            }
        case 'ADD':
            const jam = action.payload.data.jam || '';
            const aktivitas = action.payload.data.aktivitas || '';  
            return {
                ...state,
                todolist: { 
                    data:[{jam, aktivitas}, ...state.todolist.data]
                }
            }
        default:
        return state
    }
}
export default TodoList;