import './404.css';
function NotFound(props) {
    return (
        <div> 
            <div className="bg-light">
            <p className="mega">
                4<span className="boom">0</span>4
            </p>
            <p id="spinner" className="mini">That's an error.</p>
            </div>
        </div>
        )
}

export default NotFound;