import React from 'react';
import {NavLink, useLocation  } from 'react-router-dom';
import "./menu.css";
const Menu =(props)=>{
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [selected, setSelected]= React.useState("home");
    const [show, setShow] = React.useState(false);
    const itemMenu = [
        {name: 'home', text: 'contact', iconStyle: 'ion-md-contact'},
        {name: 'education', text: 'education', iconStyle: 'ion-md-ribbon'},
        {name: 'skills', text: 'skills', iconStyle: 'ion-md-construct'},
        {name: 'done', text: 'other', iconStyle: 'ion-md-done-all'}
    ]
    React.useEffect(()=>(
        setSelected(splitLocation[1] === "" ? "home" : splitLocation[1])
    ),[splitLocation])
     
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
        
    const handleScroll = () => {
        setShow(window.pageYOffset > 140);
    };
    console.log(show);
    return(
        <div className={`wrap-naviation mb-3  ${show && "sticky"}`}>
            <div className={`naviation mb-3 `}>
                <ul>
                    {itemMenu.map((menu, i)=>(
                        <ListMenu key={i} selected={selected === menu.name ? true : false} icon={menu.iconStyle} name={menu.name} text={menu.text}/>
                    ))} 
                    <div className="indicator"></div>
                </ul>
                {show}
                <br/>
            </div>
        </div>
    )
}

const ListMenu=({andleClick, selected, icon, name, text}) => {
    let classes = "list";
    if(selected) {
        classes += " active" 
    }
    return(
        <li className={classes}>
            <NavLink to={`${name}`}>
                <span className={`icon`}> <i className={`icon ${icon}`}></i></span>
                <span className="text">{text}</span>
            </NavLink>
        </li>
    )
};

const MenuSecond = () =>{
    const [selectedSosmed, setSelectedSosmed]= React.useState('twitter');
    const itemMenu = [
        {name: 'facebook', text: 'facebook', iconStyle: 'ion-logo-facebook', link: 'https://www.facebook.com/profile.php?id=100069880992928'},
        {name: 'twitter', text: 'twitter', iconStyle: 'ion-logo-twitter', link: 'https://twitter.com/Purdin86179332'},
        {name: 'instagram', text: 'instagram', iconStyle: 'ion-logo-instagram', link: 'https://www.instagram.com/purkonuddin/'},
        {name: 'whatsapp', text: 'whatsapp', iconStyle: 'ion-logo-whatsapp', link: 'https://wa.link/x3k6dw'}
    ]
    const adleClickSosmed = (name)=>{
        setSelectedSosmed(name)
    } 
    return(
        <div className="naviation-second">
            <ul>
                {itemMenu.map((menu, i)=>(
                    <ListSosmed 
                    key={i} 
                    andleClick={adleClickSosmed} 
                    selected={selectedSosmed === menu.name ? true : false} 
                    icon={menu.iconStyle} 
                    name={menu.name} 
                    text={menu.text}
                    link={menu.link}/>
                ))} 
                <div className="indicator-second"></div>
            </ul>
        </div>
    )
}

const ListSosmed=({andleClick, selected, icon, name, text, link}) => {
    let classes = "list";
    let spinner = "";
    if(selected) {
        classes += " active"
        spinner += " active-spinner"
    }
    const openPae=(link)=>{
        window.open(`${link}`, "_blank", "toolbar=yes,top=000,left=000,width=400,height=400");
    }
    // console.log(`nama : ${name}`);
    return(
        <li className={classes}>
            <a href="# " name={name} onClick={()=>andleClick(name)}>
                <span className={`icon ${spinner}`}> <i className={`icon ${icon}`} onClick={()=>openPae(link)}></i></span>
                <span className="text" onClick={()=>openPae(link)}>{text}</span>
            </a>
        </li>
    )
};

export {Menu, MenuSecond};
